import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiResponse} from "../../../pages/greenhouse/models/api-response";
import {environment} from "../../../../environments/environment";
import {Moment} from "moment";

export interface StationStats {
    minAirH: number;
    maxWindS: number;
    minSoilEC: number;
    sumET0: number | null;
    maxAirH: number;
    meanWindS: number;
    maxSoilEC: number;
    defaultCumDD: number;
    stationID: string;
    meanAirH: number;
    minSoilT: number;
    meanSoilEC: number;
    created_at: string;
    minAirP: number;
    maxSoilT: number;
    sumDif: number | null;
    updated_at: string;
    measurementDate: string;
    maxAirP: number;
    meanSoilT: number;
    minAirT: number;
    meanAirP: number;
    minSoilVWC: number;
    sumDni: number | null;
    maxAirT: number;
    sumRain: number;
    maxSoilVWC: number;
    sumGhi: number | null;
    meanAirT: number;
    minWindS: number;
    meanSoilVWC: number;
    sumGni: number | null;
}

@Injectable({
    providedIn: "root"
})
export class StationsService {
    private readonly baseUrl = environment.modelApiUrl;

    constructor(
        private http: HttpClient
    ) {
    }

    getAllStationIds() {
        return this.http.get<ApiResponse<string[]>>(`${this.baseUrl}/stations`).toPromise();
    }

    getStationStats(stationID: string, startDate: Moment, endDate: Moment) {
        return this.http.post<ApiResponse<StationStats[]>>(`${this.baseUrl}/stations`, {
            stationID,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
        }).toPromise();
    }
}
