import {Component, Input} from "@angular/core";

@Component({
    selector: "ngx-custom-progress-bar",
    templateUrl: "./custom-progress-bar.component.html",
    styleUrls: ["./custom-progress-bar.component.scss"]
})
export class CustomProgressBarComponent {
    @Input() color: string = "#4c94ff";  // Progress bar color
    @Input() height: string = "2px";  // Height of the progress bar
    constructor() {
    }
}
