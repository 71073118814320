import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild,} from "@angular/core";
import {GlobalQuery} from "../../../store/global";
import {RainspotComponent} from "../rainspot/rainspot.component";
import {DialogService} from "primeng/dynamicdialog";
import {MeteostationsQuery} from "../../../store/meteostations/state";
import {MessageService} from "primeng/api";
import {HelperService} from "../../../@core/utils/helper.service";

@Component({
    selector: "ngx-statistic-table",
    templateUrl: "./statistic-table.component.html",
    styleUrls: ["./statistic-table.component.scss"],
    providers: [DialogService, MessageService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticTableComponent implements OnInit {
    @ViewChild("table") table: ElementRef;
    @Input() statisticData;
    @Input() statisticMeta;
    @Input() type;
    public hourlyData;
    public title: string;
    public timeKey: string;
    public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    public windDirection: string;
    public selectedTimeRange: string = "hourly";

    constructor(
        public globalQuery: GlobalQuery,
        private dialogService: DialogService,
        private meteostationQuery: MeteostationsQuery,
        private helperService: HelperService,
    ) {
    }

    ngOnInit(): void {
        if (this.type === "forecast") {
            this.title = $localize`Forecast Data`;
            this.timeKey = "time";
            this.windDirection = "winddirection";
        }
        if (this.type === "meteostation") {
            this.title = $localize`Meteorological Data`;
            this.timeKey = "Time";
            this.windDirection = "WindD";
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.hasOwnProperty('statisticData')) {
            if(this.selectedTimeRange === "daily") {
                this.hourlyData = [...changes.statisticData?.currentValue];
                this.statisticData = this.generateDailyData();
            } else {
                this.statisticData = [...changes.statisticData?.currentValue];
                this.hourlyData = [...this.statisticData];
            }
        }
    }

    public regenerateTableData(): void {
        if(this.selectedTimeRange === "daily") {
            this.statisticData = this.generateDailyData();
        } else {
            this.statisticData = [...this.hourlyData];
        }
    }

    public openDialog(item: any) {
        this.dialogService.open(RainspotComponent, {
            data: {item},
            styleClass: "responsiveDialog",
        });
    }

    private generateDailyData() {
        const days = {};

        for (let data of this.statisticData) {
            const newDate = new Date(data[this.timeKey]);
            const date = newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate();
            const month = newDate.getMonth() + 1 > 9 ? newDate.getMonth() + 1 : '0' + (newDate.getMonth() + 1);
            const year = newDate.getFullYear();
            const formattedDate = `${year}-${month}-${date}`;
            if (days.hasOwnProperty(formattedDate)) {
                days[formattedDate].push(data);
            } else {
                days[formattedDate] = [data];
            }
        }

        const dailyData = [];
        for (const date in days) {
            if (days.hasOwnProperty(date)) {
                let newDay = {};
                const dayData = days[date];
                const day = dayData[0];
                const dayKeys = Object.keys(day);
                for (const key of dayKeys) {
                    if (key !== 'time' && key !== 'Time') {
                        const values = dayData.map((data) => data[key]).filter(data => data !== null);
                        const sum = values.reduce((a, b) => a + b, 0);
                        let average = sum / values.length;
                        if(key == 'pictocode') {
                            average = Math.round(average);
                        }
                        if(key == 'Rain') {
                            newDay[key] = sum;
                        } else {
                            newDay[key] = average;
                        }
                    }
                }
                newDay[this.timeKey] = date;
                dailyData.push(newDay);
            }
        }

        return dailyData;
    }

    public exportToExcel(tableRef: ElementRef): void {
        // const device = this.meteostationQuery.getValue().currentMeteostation;
        // this.excelService.exportTableToExcel(table.nativeElement, device.name);

        const table: HTMLTableElement = tableRef.nativeElement;
        const device = this.meteostationQuery.getValue().currentMeteostation;
        const deviceName = device.name.replace(/\s+/g, '_').replace(/[',]+/g, '');
        const serialNumber = device.serial_number;
        this.helperService.generateCSVText(deviceName + '_' + serialNumber, this.statisticData, table);
        // const filename = deviceName + '_' + serialNumber + '.xlsx';
        // this.helperService.generateExcelFile(this.statisticData, table, filename);
        // this.helperService.getExcelFile(filename).subscribe(
        //     (response: Blob) => {
        //         const link = document.createElement('a');
        //         link.href = window.URL.createObjectURL(response);
        //         link.setAttribute('download', filename);
        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);
        //         this.messageService.add(
        //             {
        //                 key: 'excel-file',
        //                 severity: 'success',
        //                 summary: $localize`Done`,
        //                 detail: $localize`The file successfully downloaded`
        //             }
        //         )
        //     },
        //     (error: HttpErrorResponse) => {
        //         console.error('Error converting file:', error);
        //         this.messageService.add(
        //             {
        //                 key: 'excel-file',
        //                 severity: 'error',
        //                 summary: $localize`Error`,
        //                 detail: $localize`An error occured`
        //             }
        //         )
        //     }
        // );
    }
}
