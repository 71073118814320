export interface MeteostationParameters {
    [p: string]: Parameter;
}

export interface Parameter {
    field: string;
    caption: string;
    suffix: string;
    fraction: number;
    isForecast: boolean;
    isStatistic: boolean;
    isCurrentReading: boolean;
    isSpecialReading: boolean;
    imgSrc?: string;
    imgClass?: string;
    extraForCurrentReadings?: string[];
    forecastField?: string;
    stationField?: string;
    availableAsCurrentReading?: string[];
}

export const parameters: MeteostationParameters = {
    AirT: {
        field: "AirT",
        caption: $localize`Air Temperature`,
        suffix: "°C",
        fraction: 1,
        imgSrc: "thermometer.png",
        imgClass: "air-t",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: true,
        isSpecialReading: false,
        forecastField: "temperature",
        extraForCurrentReadings: ["felttemperature", "dewpointtemperature"],
        availableAsCurrentReading: ["comparison-dashboard"],
    },
    AirH: {
        field: "AirH",
        caption: $localize`Relative humidity`,
        suffix: "%",
        fraction: 1,
        imgSrc: "humidity.png",
        imgClass: "air-h",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: true,
        isSpecialReading: false,
        forecastField: "relativehumidity",
        availableAsCurrentReading: ["comparison-dashboard"],
    },
    Rain: {
        field: "Rain",
        caption: $localize`Rain`,
        suffix: "mm",
        fraction: 1,
        isForecast: false,
        isStatistic: true,
        isCurrentReading: true,
        isSpecialReading: false,
        imgSrc: "rain.png",
        forecastField: "convective_precipitation",
        availableAsCurrentReading: ["comparison-dashboard"],
        extraForCurrentReadings: ["Rain"]
    },
    WindS: {
        field: "WindS",
        caption: $localize`Wind speed`,
        suffix: "m/s",
        fraction: 1,
        imgSrc: "wind.png",
        imgClass: "wind-s",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: true,
        isSpecialReading: false,
        forecastField: "windspeed",
        extraForCurrentReadings: ["WindD"],
        availableAsCurrentReading: ["comparison-dashboard"],
    },
    WindMax: {
        field: "WindMax",
        caption: $localize`Wind max speed`,
        suffix: "m/s",
        fraction: 1,
        imgSrc: "wind.png",
        imgClass: "wind-s",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        extraForCurrentReadings: ["WindD"],
    },
    WindD: {
        field: "WindD",
        caption: $localize`Wind direction`,
        suffix: "",
        fraction: 0,
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        forecastField: "winddirection",
    },
    SoilT: {
        field: "SoilT",
        caption: $localize`Soil Temperature`,
        suffix: "°C",
        fraction: 1,
        imgSrc: "soil-temperature.png",
        imgClass: "soil-t",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: true,
        isSpecialReading: false,
        forecastField: "soiltemperature_0to10cm",
        extraForCurrentReadings: ["evapotranspiration"],
    },
    SoilT_1: {
        field: "SoilT_1",
        caption: $localize`Soil Temperature (0.6m)`,
        suffix: "°C",
        fraction: 1,
        imgSrc: "soil-temperature.png",
        imgClass: "soil-t",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    SoilT_2: {
        field: "SoilT_2",
        caption: $localize`Soil Temperature (0.9m)`,
        suffix: "°C",
        fraction: 1,
        imgSrc: "soil-temperature.png",
        imgClass: "soil-t",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    SoilT_3: {
        field: "SoilT_3",
        caption: $localize`Soil Temperature (1.2m)`,
        suffix: "°C",
        fraction: 1,
        imgSrc: "soil-temperature.png",
        imgClass: "soil-t",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    SoilVWC: {
        field: "SoilVWC",
        caption: $localize`Soil VWC`,
        suffix: "%",
        fraction: 1,
        isForecast: false,
        isStatistic: true,
        isCurrentReading: true,
        isSpecialReading: false,
        imgSrc: "soil-vwc.png",
        imgClass: "soil-vwc",
        forecastField: "soilmoisture_0to10cm",
        extraForCurrentReadings: ["SoilEC"],
    },
    SoilVWC_1: {
        field: "SoilVWC_1",
        caption: $localize`Soil VWC (0.6m)`,
        suffix: "%",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "soil-vwc.png",
        imgClass: "soil-vwc",
    },
    SoilVWC_2: {
        field: "SoilVWC_2",
        caption: $localize`Soil VWC (0.9m)`,
        suffix: "%",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "soil-vwc.png",
        imgClass: "soil-vwc",
    },
    SoilVWC_3: {
        field: "SoilVWC_3",
        caption: $localize`Soil VWC (1.2m)`,
        suffix: "%",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "soil-vwc.png",
        imgClass: "soil-vwc",
    },
    SoilEC: {
        field: "SoilEC",
        caption: $localize`Soil EC`,
        suffix: "mS/sm",
        fraction: 2,
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "soil-ec.png",
        imgClass: "soil-ec",
    },
    AirPressure: {
        field: "AirP",
        caption: $localize`Air Pressure`,
        suffix: "mBar",
        fraction: 0,
        imgSrc: "air-pressure.png",
        imgClass: "air-pressure",
        isForecast: false,
        isStatistic: true,
        isCurrentReading: true,
        isSpecialReading: false,
        extraForCurrentReadings: [],
        forecastField: "localpressure",
        availableAsCurrentReading: ["comparison-dashboard"],
    },
    DewPointT: {
        field: "DewPointT",
        caption: $localize`Dew point`,
        suffix: "°C",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
        forecastField: "dewpointtemperature",
        imgSrc: "dew-point.png",
        imgClass: "dew-point",
    },
    WetBulbT: {
        field: "WetBulbT",
        caption: $localize`Wetbulb temperature`,
        suffix: "°C",
        fraction: 2,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
        forecastField: "wetbulbtemperature",
    },
    LeafT: {
        field: "LeafT",
        caption: $localize`Leaf temperature`,
        suffix: "°C",
        fraction: 0,
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        availableAsCurrentReading: ["comparison-dashboard"],
    },
    LeafW: {
        field: "LeafW",
        caption: $localize`Leaf wetness`,
        suffix: "%",
        fraction: 0,
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        availableAsCurrentReading: ["comparison-dashboard"],
    },
    AQI: {
        field: "AQI",
        caption: $localize`AQI`,
        suffix: "",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    "PM1.0": {
        field: "PM1.0",
        caption: $localize`PM 1.0`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    "PM2.5": {
        field: "PM2.5",
        caption: $localize`PM 2.5`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        forecastField: "pm25",
        extraForCurrentReadings: ["PM1.0", "PM10"],
    },
    PM10: {
        field: "PM10",
        caption: $localize`PM 10`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        forecastField: "pm10",
    },
    AlphaPM1_0: {
        field: "AlphaPM1_0",
        caption: $localize`PM 1.0 (Alpha)`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    AlphaPM2_5: {
        field: "AlphaPM2_5",
        caption: $localize`PM 2.5 (Alpha)`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    AlphaPM10: {
        field: "AlphaPM10",
        caption: $localize`PM 10 (Alpha)`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    RikaPM1_0: {
        field: "RikaPM1_0",
        caption: $localize`PM 1.0 (Rika)`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    RikaPM2_5: {
        field: "RikaPM2_5",
        caption: $localize`PM 2.5 (Rika)`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    RikaPM10: {
        field: "RikaPM10",
        caption: $localize`PM 10 (Rika)`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    NovaPM2_5: {
        field: "NovaPM2_5",
        caption: $localize`PM 2.5 (Nova)`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    NovaPM10: {
        field: "NovaPM10",
        caption: $localize`PM 10 (Nova)`,
        suffix: "μg/m3",
        fraction: 0,
        imgSrc: "dust.png",
        imgClass: "dust",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
    },
    CO2: {
        field: "CO2",
        caption: $localize`CO2`,
        suffix: "ppm",
        fraction: 0,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        imgSrc: "co2.png",
        imgClass: "co2",
    },
    GAS_NO2: {
        field: "GAS_NO2",
        caption: $localize`Nitrogen Dioxide`,
        suffix: "ppm",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        imgSrc: "co2.png",
        imgClass: "co2",
    },
    GAS_CO: {
        field: "GAS_CO",
        caption: $localize`Carbon Monoxide`,
        suffix: "ppm",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        imgSrc: "co2.png",
        imgClass: "co2",
    },
    GAS_NH3: {
        field: "GAS_NH3",
        caption: $localize`Ammonia`,
        suffix: "ppm",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        imgSrc: "co2.png",
        imgClass: "co2",
    },
    GAS_SO2: {
        field: "GAS_SO2",
        caption: $localize`Sulphur Dioxide`,
        suffix: "ppm",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        imgSrc: "co2.png",
        imgClass: "co2",
    },
    GAS_H2S: {
        field: "GAS_H2S",
        caption: $localize`Hydrogen Sulfide`,
        suffix: "ppm",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        imgSrc: "co2.png",
        imgClass: "co2",
    },
    GAS_O3: {
        field: "GAS_O3",
        caption: $localize`Ozone`,
        suffix: "ppm",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: true,
        imgSrc: "co2.png",
        imgClass: "co2",
    },
    SolarRad: {
        field: "SolarRad",
        caption: $localize`Solar Radiation`,
        suffix: "Wt/m2",
        fraction: 0,
        imgSrc: "radiation.png",
        imgClass: "radiation",
        isForecast: false,
        isStatistic: false,
        isCurrentReading: true,
        isSpecialReading: false,
        extraForCurrentReadings: ["uvindex"],
    },
    vpd: {
        field: "vpd",
        caption: $localize`Vapor pressure deficit`,
        suffix: "kPa",
        fraction: 1,
        isForecast: false,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    Batt: {
        field: "Batt",
        caption: $localize`Battery`,
        suffix: "Volt",
        fraction: 1,
        isForecast: false,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "battery.png",
        imgClass: "battery",
    },
    dewpointtemperature: {
        field: "dewpointtemperature",
        caption: $localize`Dew point`,
        suffix: "°C",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "dew-point.png",
        imgClass: "dew-point",
    },
    evapotranspiration: {
        field: "evapotranspiration",
        caption: $localize`Evapotranspiration`,
        suffix: "mm",
        fraction: 2,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "evaporation.png",
        imgClass: "evaporation",
    },
    temperature: {
        field: "temperature",
        caption: $localize`Forecasted temperature`,
        suffix: "°C",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    relativehumidity: {
        field: "relativehumidity",
        caption: $localize`Forecasted relative humidity`,
        suffix: "%",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    precipitation: {
        field: "precipitation",
        caption: $localize`Precipitation`,
        suffix: "mm",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    precipitation_probability: {
        field: "precipitation_probability",
        caption: $localize`Precipitation probability`,
        suffix: "%",
        fraction: 0,
        isForecast: true,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    convective_precipitation: {
        field: "convective_precipitation",
        caption: $localize`Convective Precipitation`,
        suffix: "mm",
        fraction: 1,
        isForecast: true,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    potentialevapotranspiration: {
        field: "potentialevapotranspiration",
        caption: $localize`Potential evapotranspiration`,
        suffix: "mm",
        fraction: 1,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    referenceevapotranspiration_fao: {
        field: "referenceevapotranspiration_fao",
        stationField: "sumET0",
        caption: $localize`Reference evapotranspiration`,
        suffix: "mm",
        fraction: 4,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    gni_instant: {
        field: "gni_instant",
        stationField: "sumGhi",
        caption: $localize`Solar Radiation`,
        suffix: "Wt/m2",
        fraction: 0,
        imgSrc: "radiation.png",
        imgClass: "radiation",
        isForecast: true,
        isStatistic: false,
        isCurrentReading: true,
        isSpecialReading: false,
        extraForCurrentReadings: ["uvindex"],
    },
    uvindex: {
        field: `uvindex`,
        caption: $localize`UV index`,
        suffix: "",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    leafwetnessindex: {
        field: "leafwetnessindex",
        caption: $localize`Leaf Wetness Index`,
        suffix: "%",
        fraction: 1,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    leafwetness_dewindex: {
        field: "leafwetness_dewindex",
        caption: $localize`Leaf Wetness Dew Index`,
        suffix: "%",
        fraction: 1,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    leafwetness_evaporationindex: {
        field: "leafwetness_evaporationindex",
        caption: $localize`Leaf Wetness Evaporation Index`,
        suffix: "%",
        fraction: 1,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    leafwetness_rainindex: {
        field: "leafwetness_rainindex",
        caption: $localize`Leaf Wetness Rain Index`,
        suffix: "%",
        fraction: 1,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    leafwetness_probability: {
        field: "leafwetness_probability",
        caption: $localize`Leaf Wetness Probability`,
        suffix: "%",
        fraction: 1,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    soiltemperature_0to10cm: {
        field: "soiltemperature_0to10cm",
        caption: $localize`Soil temperature(0-10cm)`,
        suffix: "°C",
        fraction: 2,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    soilmoisture_0to10cm: {
        field: "soilmoisture_0to10cm",
        caption: $localize`Soil moisture(0-10cm)`,
        suffix: "%",
        fraction: 2,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    pm10: {
        field: `pm10`,
        caption: $localize`PM10`,
        suffix: "μg/m3",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    pm25: {
        field: `pm25`,
        caption: $localize`PM2.5`,
        suffix: "μg/m3",
        fraction: 0,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    so2: {
        field: "so2",
        caption: $localize`Sulphur dioxide`,
        suffix: "ppb",
        fraction: 0,
        isForecast: true,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "",
        imgClass: "",
    },
    co: {
        field: "co",
        caption: $localize`Carbon monoxide`,
        suffix: "ppm",
        fraction: 0,
        isForecast: true,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "",
        imgClass: "",
    },
    no2: {
        field: "no2",
        caption: $localize`Nitrogen dioxide`,
        suffix: "ppb",
        fraction: 0,
        isForecast: true,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
        imgSrc: "",
        imgClass: "",
    },
    wetbulbtemperature: {
        field: "wetbulbtemperature",
        caption: $localize`Wetbulb temperature`,
        suffix: "°C",
        fraction: 2,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    windspeed: {
        caption: $localize`Wind speed`,
        field: "windspeed",
        suffix: "m/s",
        fraction: 1,
        isForecast: true,
        isStatistic: false,
        isCurrentReading: false,
        isSpecialReading: false,
    },
    winddirection: {
        caption: $localize`Wind direction`,
        field: "winddirection",
        suffix: "",
        fraction: 0,
        isForecast: true,
        isStatistic: true,
        isCurrentReading: false,
        isSpecialReading: false,
    },
};
